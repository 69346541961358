import styled from 'styled-components'
import { darken, linearGradient } from 'polished';

export const Container = styled.div`
  height: auto;
  min-height: 100vh;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${(props) => linearGradient({
    colorStops: [
      `${props.theme.colors.cyanDark}`,
      `${darken('0.001', `${props.theme.colors.cyanDark}`)}`,
    ],
  })}
`;

export const Box = styled.div`
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${(props) => `0 0 20px ${props.theme.colors.blueCyanDarkest}`};
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    border-radius: 12px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  max-width: 12rem;
  align-self: center;

  @media (max-width: 450px) {
    width: 56%;
    padding-bottom: 24px;
  }
`;

export const Span = styled.span`
  text-align: center;
  font-weight: 700;

  @media (max-width: 450px) {
    border-radius: 12px;
  }
`;
