import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import { T2 } from '../../../styles/titles';



import LoadingOverlay from '../../../components/LoadingOverlay';
import ZeroState from '../../../components/ZeroState';
import {formatDate, formatDateToDateTime} from "../../../utils/formatDate";
import {getAllVendas} from "../../vendas/actions";
import {Col, Pagination, Row} from "antd";
import {getAxiosInstanceLinkPagamento} from "../../../services/providers/api";


import {BusinessForm, Container, DateView} from './styles';
import FormInput from "../../../components/Forms/Input";
import { Formik, Field, Form } from 'formik';
import DatePickerField from "../../../components/DatePicker";
import SelectField from "../../../components/Select";
import {ISelectValue} from "../../../global/types";
import {
  AlignCheckbox,
  Button,
  CheckboxContainer,
  CheckboxTitle
} from "../../business/newBusiness/components/Form/style";
import {useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {Toast} from "react-toastify/dist/components";

interface FormValues {
  name: string;
  valueReais: string;
  dataExpiracao: Date | null;
  description: string;
  parcelas: ISelectValue | null;
  credito: boolean;
  debito: boolean;
  pagamentoUnico: boolean;
  primeiraCobranca: Date | null;
  recurringTime: number;
  periodo:  ISelectValue | null;
}

const CadastrarAssinaturas: React.FC = () => {
  const history = useHistory();
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) :null;
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(0);
  useEffect(() => {
    //@ts-ignore
   const user = JSON.parse(localStorage.getItem('userInfo'));
   setUserId(user.id);

  }, []);

  const cadastraLink = async (submit:any) => {
    try {
      setLoading(true)
      const response = await getAxiosInstanceLinkPagamento().post('link-pagamento/assinatura/criar/'+selectedBusiness.merchantId, submit);
      setLoading(false)
      history.push('/listar-assinaturas');
    }catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const handleSetCurrencyMask = useMemo(() => (
    e: React.FormEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    name: string,
  ): React.FormEvent<HTMLInputElement> => {
    let { value } = e.currentTarget;

    value = value.replace(/\D/g, '');
    value = value.replace(/(\d)(\d{2})$/, '$1,$2');
    value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

    e.currentTarget.value = value;
    setFieldValue(name, value);
    return e;
  }, []);


  const periodos: ISelectValue[] = [
    { label: 'Dia(s)', value: 'dia' },
    { label: 'Semana(as)', value: 'semana' },
    { label: 'Mes(es)', value: 'mes' },
    { label: 'Ano(s)', value: 'ano' },
  ];

  const parcelas: ISelectValue[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
  ];

  const formatDateToYYYYMMDD = (date:any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const calculateDaysDifference = (specifiedDate:any):number => {
    const currentDate = new Date();
    const dayBefore = new Date(currentDate);
    dayBefore.setDate(currentDate.getDate() - 1);
    // Verifica se a data especificada é maior que a data atual
    if (specifiedDate <= dayBefore) {
      toast.error('A data especificada deve ser maior que a data atual.');
      return 0;
    }

    const timeDifference = specifiedDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (
    <Container>
      <LoadingOverlay
        show={loading}
        relative
      />

      <T2>Cadastrar link de pagamento</T2>
      <Formik<FormValues>
        onSubmit={async (values:any) => {
          console.log(values);
          if(values.name == ''){
            toast.error('Nome do produto é obrigatório!')
            return
          }

          if(values.value == ''){
            toast.error('Valor é obrigatório!')
            return
          }

          if(values.dataExpiracao == null){
            toast.error('Data de expiração é obrigatório!')
            return
          }

          if(values.description == ''){
            toast.error('Descrição é obrigatório!')
            return
          }

          if(values.recurringTime == 0 || values.recurringTime == null) {
            toast.error('O campo cobrar a cada é obrigatório!')
            return
          }

          if(values.primeiraCobranca == null){
            toast.error('Primeira cobrança é obrigatório!')
            return
          }

          const currentDate = new Date();
          const dayBefore = new Date(currentDate);
          dayBefore.setDate(currentDate.getDate() - 1);

          // Verifica se a data especificada é maior que a data atual
          if (values.primeiraCobranca <= dayBefore) {
            toast.error('A data da cobrança deve ser maior que a data atual.');
            return;
          }

          let recurring = {recurring_rule:"",start_billing:0}

          let recurring_rule = "";
          if(values.periodo == null){
            values.periodo = { label: 'Mes(es)', value: 'mes' };
          }

          if(values.periodo.value == 'dia') {
            recurring_rule = `FREQ=DAILY;INTERVAL=${values.recurringTime};`
          }else if (values.periodo.value == 'semana') {
            recurring_rule = `FREQ=WEEKLY;INTERVAL=${values.recurringTime};`
          }else if (values.periodo == null || values.periodo.value == 'mes') {
            recurring_rule = `FREQ=MONTHLY;INTERVAL=${values.recurringTime};`
          }else if (values.periodo.value == 'ano') {
            recurring_rule = `FREQ=YEARLY;INTERVAL=${values.recurringTime};`
          }

          const diasParaPrimeiraCobranca = calculateDaysDifference(values.primeiraCobranca);

          console.log(diasParaPrimeiraCobranca);

          recurring_rule+= `BYMONTHDAY=${values.primeiraCobranca.getDate()};`
          recurring.recurring_rule = recurring_rule
          values.recurring_rule = recurring_rule

          recurring.start_billing = diasParaPrimeiraCobranca

          values.recurring = recurring;

          if(values.parcelas != null){
            values.installment = {}
            values.installment.installment_limit = parseInt(values.parcelas.value)
          }else {
            values.installment = {}
            values.installment.installment_limit = 1
          }
          values.payment_methods = []
          values.payment_methods.push('CREDIT')

          if(values.payment_methods.length == 0) {
            toast.error('Selecione pelo menos uma forma de pagamento!')
            return
          }

          if(values.dataExpiracao != null){
            values.expires_at = formatDateToYYYYMMDD(values.dataExpiracao)
          }

          if(values.pagamentoUnico) {
            values.sales_limit = 1;
          }

          values.usuario_id = userId
          const normalizedValue = values.valueReais.replace(/\./g, '').replace(',', '.');;
          const numberValue = parseFloat(normalizedValue);
          values.value = numberValue* 100

          cadastraLink(values)
        }}
        initialValues={{
          name: '',
          valueReais: '',
          dataExpiracao: null,
          description: '',
          parcelas:  { label: '1', value: '1' },
          credito: false,
          debito: false,
          pagamentoUnico: false,
          primeiraCobranca: null,
          recurringTime: 1,
          periodo:  { label: 'Mes(es)', value: 'mes' },
        }}>

        {({ setFieldValue, values }) => (
      <Form>
        <Row gutter={18} align="top" justify="start">
          <Col span={12}>
            <FormInput
              type="text"
              name="name"
              label="Nome do produto"
              required
              placeholder="O que você está vendendo"
            />
          </Col>
          <Col span={12}>
            <FormInput
              type="text"
              name="valueReais"
              label="Valor"
              required
              placeholder="0,00"
              prefix="R$"
              onChange={(e: React.FormEvent<HTMLInputElement>) => handleSetCurrencyMask(e, setFieldValue, 'valueReais')}
            />
          </Col>
        </Row>
          <Row gutter={18} align="top" justify="start">
            <Col span={12}>
              <DateView>
                <DatePickerField
                  name="dataExpiracao"
                  label="Expira em"
                  required
                  placeholder="Data de expiração"
                />
              </DateView>
            </Col>
            <Col span={12}>
              <FormInput
                type="text"
                name="description"
                label="Descrição do link"
                required
                placeholder="Descrição do produto ou serviço"
              />
            </Col>
          </Row>


        <Row gutter={18} align="top" justify="start">
          <Col span={12}>
            <FormInput
              type="text"
              name="recurringTime"
              label="Cobrar a cada"
              required
              placeholder="1"
            />
          </Col>
          <Col span={12}>
            <SelectField
              name="periodo"
              label="Período"
              options={periodos}
              placeholder=""
            />
          </Col>
        </Row>


        <Row gutter={18} align="top" justify="start">
          <Col span={12}>
            <DateView>
              <DatePickerField
                name="primeiraCobranca"
                label="Primeira cobrança em"
                required
                placeholder="Primeira cobrança em"
              />
            </DateView>
          </Col>
        </Row>


        <Row justify="end">
          <Button htmlType="submit">
            Salvar
          </Button>
        </Row>


      </Form>
          )}
      </Formik>

    </Container>
  );
}
export default CadastrarAssinaturas;
