import {getAxiosInstance} from "../../../../services/providers/api";
import {PainelVendasFilterProps} from "../../../../global/dataTransferObjects/transacoesTempoReal";


export default class PainelDePagamentosService {

  getPainelDePagamentos(filter: any) {
    if(filter.acquirer == -1){
      delete filter.acquirer
    }
    return getAxiosInstance().post('/transacoes/payments',filter)
  }


  salvarPagamentos(liveTransactions: any) {
    return getAxiosInstance().post('/transacoes/update-payments',liveTransactions)
  }

  async getFileDownload(filter?: PainelVendasFilterProps) {
    const result = await getAxiosInstance().post('/transacoes/payments-xls', filter, {responseType: 'blob'});
    return result.data;
  }
}
