import React, {useEffect, useReducer} from 'react';
import {DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import useCurrentUser from '../../../hooks/useCurrentUser';
import UserAvatar from './UserAvatar';
import {Dropdown, Header} from '../styles';
import { IHeaderMenuProps } from '../types';
import createBusinessLinked from '../../../services/companyLikend';
import { ISelectValue } from '../../Forms/AsyncFormDropdown/types';
import FormFilter from '../../Forms/SelectEmpresas';
import { saveCompanySelected } from '../../../store/Company';
import { ISelectedBusiness } from '../../../global/dataTransferObjects/business';
import {MenuProps} from "antd";
import Typography from 'antd/lib/typography/Typography';
import Space from "antd/lib/space";
import Reducer, {InitialState} from "../../../store/Company/reducer";
import Select from "antd/lib/select";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import PageMenuPJ from "../../PageWrapperPessoaJuridica/components/PageMenuPJ";

const HeaderMenu: React.FC<IHeaderMenuProps> = (props: IHeaderMenuProps) => {
  const {
    onSignOutButtonClick,
    collapsed,
    toggle,
    onSaveCompanySelected,
    onModalStatus,
  } = props;
  const { name } = useCurrentUser();
  const history = useHistory();
  const location = useLocation();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const businessListService = createBusinessLinked()
  const [empresas, setEmpresas] = React.useState<Array<any>>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<any>(null);
  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    setEmpresas(localStorage.getItem('empresas') ? JSON.parse(localStorage.getItem('empresas') as string) : []);
    setSelectedCompany(localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) : null);
    console.log(selectedCompany);
  }, []);

  const handleLoadBusinessOptions = async (): Promise<Array<any>> => {
    const allBusiness: any = await businessListService.getCompanyLinkedList();
    const options = allBusiness.response.map((item: any) => ({
      value: item.id,
      label: item.name,
    }))
    return options;
  }

  const handleCompanySelected = (id:any) => {
    localStorage.setItem('selectedCompany', JSON.stringify(empresas.find((item) => item.value === id)));
    setSelectedCompany(empresas.find((item) => item.value === id));

    window.location.assign(`/tecpay/admin/painel-de-vendas`);
  }


  return (
    <Header>
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: toggle,
      })}
      <><div className='pages'><PageMenuPJ /></div>

        <Select
          defaultValue={selectedCompany}
          style={{width: 200}}
          value={selectedCompany}
          onChange={handleCompanySelected}
          options={empresas}
        />
        <UserAvatar
          userName={name}
          onSignOutButtonClick={onSignOutButtonClick}
          onModalStatus={onModalStatus}
        />
      </>
    </Header>
  );
};



const mapStateToProps = (props: IHeaderMenuProps) => ({
  props,
});

const mapDispatchToProps = {
  onSaveCompanySelected: saveCompanySelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
