import React, {useMemo, useRef, useState} from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { ColumnType } from 'antd/es/table';
import {Checkbox, Input, Popconfirm} from 'antd';
import { SearchOutlined } from '@ant-design/icons/lib';
import Table, { TableColumn } from '../../../../../components/Table';
import { ITransactionsTable } from '../../types';
import { createFormatProvider } from '../../../../../services/providers/factories';
import { TableContainer, ResponsiveTable, ResponsiveRow, ResponsiveColumn} from '../../styles';
import useGetMe from "../../../../../hooks/useCurrentUser";
import {DeleteOutlined} from "@ant-design/icons";

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  quantidade: number;
  valorVenda: number;
  valorLiquido: number;
  businessName: string;
  acquirer: string;
  terminal: string;
  brand:string;
  authorizationNumber:string;
  cardNumber:string;
  productName:string;
  acquirerNsu:string;
  originalValue:string;
  status:string;
  parcels: string;
  terminalSerialNumber: string;
  liquidValue: string;
  value: string;
  receiveDate: string;
  currentParcel: string;
  paymentStatus: string;
  merchant: string;
  document: string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const user = useGetMe();
  const liveTransactions = props.liveTransactions;
  const setPagamentos = props.setPagamentos;
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const formatProvider = useMemo(() => createFormatProvider(), []);
  // const formattedDate = (date: string) => formatProvider.formatDate(Date.parse(date));
  const formattedDate = (date: string) => formatDate(date);
  const formattedStatus = (status: string) => formatStatus(status);
  const formattedMoney = (value: number) => formatMoney(value);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex:any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const formatCnpj = (cnpj:any) => {
    // Check if the input is already formatted

    if(cnpj === undefined) return;

    const formattedPattern = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    if (formattedPattern.test(cnpj)) {
      return cnpj;
    }

    // Remove all non-digit characters
    cnpj = cnpj.replace(/\D/g, '');

    // Check if the cleaned input has the correct length
    if (cnpj.length !== 14) {
      return cnpj;
    }

    // Format the CNPJ
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
  };

  const formatMoney = (value:number) => {
    const formattedValue = (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);

    // Pad single digits with leading zero
    // @ts-ignore
    const pad = (num) => (num < 10 ? `0${num}` : num);

    const formattedDate = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;

    return formattedDate;
  };
  const formatStatus = (status:any) => {
    let statusStr = '';
    if (status === 'Cancelled') {
      statusStr = 'Cancelado';
    } else if (status === 'Payed') {
      statusStr = 'Pago'
    } else if (status === 'Pending') {
      statusStr = 'Pendente'
    }

    return statusStr;
  };

  const formatAcquirer = (acquirer :any) => {
    let acquirerStr = '';
    if (acquirer === 3) {
      acquirerStr = 'Adiq';
    } else if (acquirer === 10) {
      acquirerStr = 'Rede'
    }
    return acquirerStr;
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  // if (_.isEmpty(transfer) && !state.loading) {
  //   return (
  //     <ZeroState />
  //   )
  // }
  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };


  const changeDebitoPago = (e: any, record:any) => {
    const _liveTransactions =[...liveTransactions]
    let index = _liveTransactions.findIndex((item) => item == record);
    console.log(index);
    _liveTransactions[index].debitoPago = e.target.checked;
    setPagamentos(_liveTransactions);
  }

  const changeDebitoPagoTodos = (e: any) => {
    const _liveTransactions =[...liveTransactions]
    _liveTransactions.forEach(value => {
      value.debitoPago = e.target.checked;
    });

    setPagamentos(_liveTransactions);
  }


  const changePixPago = (e: any, record:any) => {
    const _liveTransactions =[...liveTransactions]
    let index = _liveTransactions.findIndex((item) => item == record);
    _liveTransactions[index].pixPago = e.target.checked;
    setPagamentos(_liveTransactions);
  }

  const changePixPagoTodos = (e: any) => {
    const _liveTransactions =[...liveTransactions]
    _liveTransactions.forEach(value => {
      value.pixPago = e.target.checked;
    });

    setPagamentos(_liveTransactions);
  }

  const changeCreditoPago = (e: any, record:any) => {
    const _liveTransactions =[...liveTransactions]
    let index = _liveTransactions.findIndex((item) => item == record);
    console.log(index);
    _liveTransactions[index].creditoPago = e.target.checked;
    setPagamentos(_liveTransactions);
  }

  const changeCreditoPagoTodos = (e: any) => {
    const _liveTransactions =[...liveTransactions]
    _liveTransactions.forEach(value => {
      value.creditoPago = e.target.checked;
    });

    setPagamentos(_liveTransactions);
  }

  // @ts-ignore
  return (
    <div>
      <TableContainer>
        <Table pagination={false} dataSource={liveTransactions}>

         <TableColumn title="Empresa" dataIndex="businessName" key="businessName" onFilter={filter('businessName')} filterIcon={getColumnSearchProps('businessName').filterIcon} filterDropdown={getColumnSearchProps('businessName').filterDropdown} />
          {user.userType === 3 && (
            <TableColumn title="Codigo do lojista" dataIndex="merchant" key="merchant" onFilter={filter('merchant')} filterIcon={getColumnSearchProps('merchant').filterIcon} filterDropdown={getColumnSearchProps('merchant').filterDropdown} />
          )}
          <TableColumn title="CNPJ" render={formatCnpj} dataIndex="document" key="document" onFilter={filter('document')} filterIcon={getColumnSearchProps('document').filterIcon} filterDropdown={getColumnSearchProps('document').filterDropdown} />
          <TableColumn title="Quantidade" dataIndex="quantidade" key="quantidade" onFilter={filter('quantidade')} filterIcon={getColumnSearchProps('quantidade').filterIcon} filterDropdown={getColumnSearchProps('quantidade').filterDropdown} />
          <TableColumn render={formatMoney} title="Valor da venda" dataIndex="valorVenda" key="valorVenda" onFilter={filter('valorVenda')} filterIcon={getColumnSearchProps('valorVenda').filterIcon} filterDropdown={getColumnSearchProps('valorVenda').filterDropdown} />
          <TableColumn render={formatMoney} title="Valor Líquido" dataIndex="valorLiquido" key="valorLiquido" onFilter={filter('valorLiquido')} filterIcon={getColumnSearchProps('valorLiquido').filterIcon} filterDropdown={getColumnSearchProps('valorLiquido').filterDropdown} />
          <TableColumn key="debitoPago" title={
                                            <div>Débito
                                              <Checkbox onChange={ (e) => {changeDebitoPagoTodos(e)}} name="debito"></Checkbox>
                                            </div>}
                      render={(text:any, record:any) => (
                        <><Checkbox checked={record.debitoPago}
                                    onChange={ (e) => {changeDebitoPago(e,record)}}
                          name="debito">
                        </Checkbox></>
                      )}
          />
          <TableColumn  title={<div>Crédito <Checkbox
            name="credito"
            onChange={ (e) => {changeCreditoPagoTodos(e)}}
          >
          </Checkbox></div>}
                        render={(text:any, record:any) => (
                          <><Checkbox checked={record.creditoPago}
                                      onChange={ (e) => {changeCreditoPago(e,record)}}
                                      name="debito">
                          </Checkbox></>
                        )}
          ></TableColumn>
          <TableColumn title={<div>Pix <Checkbox
            name="pix"
            onChange={ (e) => {changePixPagoTodos(e)}}
          >
          </Checkbox></div>}
                       render={(text:any, record:any) => (
                         <><Checkbox checked={record.pixPago}
                                     name="debito"
                                     onChange={ (e) => {changePixPago(e,record)}}
                         >
                         </Checkbox></>
                       )}

          ></TableColumn>
        </Table>
      </TableContainer>
      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
          <ResponsiveColumn><h4>Empresa</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Qtd</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Valor da Venda</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Valor Liquido</h4></ResponsiveColumn>
        </ResponsiveRow>
        {liveTransactions.map((sale) => {
          return (
            <ResponsiveRow
              style={{ backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)", cursor: "pointer" }}
            >
              <ResponsiveColumn
              style={{width: "30%"}}>
                <p>{sale.businessName}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{sale.quantidade}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{formatMoney(sale.valorVenda)}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{formatMoney(sale.valorLiquido)}</p>
              </ResponsiveColumn>
            </ResponsiveRow>
          )
        })}
      </ResponsiveTable>
    </div>
  )
}

export default LiveTable;
