import React, {ChangeEvent, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import {FilterConfirmProps} from 'antd/lib/table/interface';
import {ColumnType} from 'antd/es/table';
import {Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons/lib';
import Table, {TableColumn} from '../../../../../components/Table';
import {ITransactionsTable} from '../../types';
import {createFormatProvider} from '../../../../../services/providers/factories';
import {ResponsiveColumn, ResponsiveRow, ResponsiveTable, TableContainer} from '../../styles';
import useGetMe from "../../../../../hooks/useCurrentUser";
import {
  formatAcquirer,
  formatChannel,
  formatCommercialHierarchy,
  formatDateView,
  formatMoney,
  formatStatus
} from "../../utils";
import {InitialState, Reducer} from "../../actions/reducer";
import {getAllLiveTransactions} from "../../actions";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import {formatDate} from "../../../../../utils/formatDate";
import Select from "antd/lib/select";

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  document: string;
  businessName: string;
  acquirer: string;
  terminal: string;
  tefTerminal: string;
  brand: string;
  authorizationNumber: string;
  cardNumber: string;
  productName: string;
  acquirerNsu: string;
  originalValue: string;
  status: string;
  parcels: string;
  terminalSerialNumber: string;
  liquidValue: string;
  value: string;
  merchant: string;
  captureChannel: string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<ITransactionsTable> = (props: ITransactionsTable) => {
  const {dataFilter, getTotals} = props;
  const user = useGetMe();
  const [state, dispatch] = useReducer(Reducer, InitialState);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [totalGross, setTotalGross] = useState(0);
  const [totalLiquid, setTotalLiquid] = useState(0);
  const [count, setCount] = useState(0);
  const [averageTicket, setAverageTicket] = useState(0);
  const [sortBy, setSortBy] = useState('desc');
  const searchInput = useRef(null);
  const formatProvider = useMemo(() => createFormatProvider(), []);

  let today: Date = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  today.setHours(0, 0, 0, 0);
  tomorrow.setHours(0 ,0 ,0 ,0);

  const [dataFilterState, setDataFilterState] = useState({
    initialDate: formatDate(today),
    finalDate: formatDate(tomorrow),
    status: 1
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  // const formattedDate = (date: string) => formatProvider.formatDateView(Date.parse(date));
  const formattedDate = (date: string) => formatDateView(date);
  const formattedStatus = (status: string) => formatStatus(status);
  const formattedMoney = (value: number) => formatMoney(value);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex:any,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const handleSortBy = (value: string): void => {
    setSortBy(value);
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={`${selectedKeys[0] || ''}`}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  // if (_.isEmpty(transfer) && !state.loading) {
  //   return (
  //     <ZeroState />
  //   )
  // }
  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };

  useEffect(() => {
    getAllLiveTransactions(
      (currentPage - 1) * pageSize,
      pageSize,
      dataFilterState,
      sortBy
    )(dispatch);
  }, [currentPage, pageSize, dataFilterState, sortBy]);

  useEffect(() => {
    if (dataFilter) {
      setCurrentPage(1);
      setDataFilterState(dataFilter);
    }
  }, [dataFilter]);

  useEffect(() => {
    if (state.allLiveTransactions.totalGross !== undefined &&
        state.allLiveTransactions.totalLiquid !== undefined &&
        state.allLiveTransactions.count !== undefined &&
        state.allLiveTransactions.averageTicket !== undefined
    ) {
      setTotalGross(state.allLiveTransactions.totalGross);
      setTotalLiquid(state.allLiveTransactions.totalLiquid);
      setCount(state.allLiveTransactions.count);
      setAverageTicket(state.allLiveTransactions.averageTicket);
    }

  }, [state.allLiveTransactions.count]);

  useEffect(() => {
    if(getTotals) {
      getTotals({
        totalGross: totalGross,
        totalLiquid: totalLiquid,
        averageTicket: averageTicket,
        count: count
      })
    }
  }, [totalGross, totalLiquid, count, averageTicket]);

  const liveTransactions = state?.allLiveTransactions?.records.map((params) => (
    {
      ...params,
    }
  ));


  return (
    <div>
      <Select
          defaultValue="Ordenar"
          style={{width: 200, marginBottom: 20}}
          onChange={handleSortBy}
          options={[
            {
              value: 'asc',
              label: 'Mais antigo',
            },
            {
              value: 'desc',
              label: 'Mais recente',
            },
          ]}
      />
      <LoadingOverlay
        show={state.loading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={liveTransactions}
          pagination={
            {
              defaultPageSize: 20,
              defaultCurrent: 1,
              current: currentPage,
              onShowSizeChange: (current: number, size: number) => {
                setPageSize(size);
                setCurrentPage(current);
              },
              total: state.allLiveTransactions.count,
              onChange: (pageNum) => {
                setCurrentPage(pageNum);
              },
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} vendas`
            }
          }
        >
          <TableColumn title="Data/Hora" dataIndex="paymentDate" key="paymentDate" render={formattedDate} onFilter={filter('paymentDate')} />
          <TableColumn title="CNPJ/CPF" dataIndex="document" key="document" />
          {(user.userType === 3 || user.userType === 6) && (
            <TableColumn title="Empresa" dataIndex="businessName" key="businessName" onFilter={filter('businessName')} />
          )}
          {(user.userType === 3 || user.userType === 6) && (
            <TableColumn title="Codigo do lojista" dataIndex="merchant" key="merchant" onFilter={filter('merchant')} />
          )}
          <TableColumn title="Terminal" dataIndex="tefTerminal" key="tefTerminal" onFilter={filter('tefTerminal')} />
          <TableColumn title="NSU" dataIndex="acquirerNsu" key="acquirerNsu" onFilter={filter('acquirerNsu')} />
          <TableColumn title="Cod. Autorização" dataIndex="authorizationNumber" key="authorizationNumber" />
          <TableColumn title="Tipo Pagamento" dataIndex="productName" key="productName" onFilter={filter('productName')} />
          <TableColumn title="Canal do pagamento" render={formatChannel} dataIndex="captureChannel" key="captureChannel" />
          <TableColumn title="Parcelas" dataIndex="parcels" key="parcels" onFilter={filter('parcels')} />
          <TableColumn title="Bandeira" dataIndex="brand" key="brand" onFilter={filter('brand')} />
          <TableColumn title="No. Cartao" dataIndex="cardNumber" key="cardNumber" onFilter={filter('cardNumber')} />
          <TableColumn title="Valor Da Venda" render={formatMoney} dataIndex="value" key="value" onFilter={filter('value')} />
          <TableColumn title="Valor Líquido" render={formatMoney} dataIndex="liquidValue" key="liquidValue" onFilter={filter('liquidValue')} />
          {user.userType === 3 && (
            <TableColumn title="Adquirente" render={formatAcquirer} dataIndex="acquirer" key="acquirer" onFilter={filter('acquirer')} />
          )}
          {user.userType === 3 && (
            <TableColumn title="Comercial" render={formatCommercialHierarchy} dataIndex="commercialHierId" key="commercialHierId" onFilter={filter('commercialHierId')} />
          )}
          <TableColumn title="Status" render={formatStatus} dataIndex="status" key="status" onFilter={filter('status')} />
        </Table>
      </TableContainer>
      <ResponsiveTable>
        <ResponsiveRow style={{ borderBottom: '1px solid gray', borderRadius: '0px'}}>
          <ResponsiveColumn><h4>Data e Status</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Pagamento e Bandeira</h4></ResponsiveColumn>
          <ResponsiveColumn><h4>Valores</h4></ResponsiveColumn>
        </ResponsiveRow>
        {liveTransactions.map((sale) => {
          return (
            <ResponsiveRow style={{backgroundColor: "white", boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.2)"}}>
              <ResponsiveColumn style={{maxWidth: "92px"}}>
                <p>{formatDateView(sale.paymentDate)}</p>
                <p style={formatStatus(sale.status) === 'Confirmada' ? {color: 'green', fontWeight: 'bold'} : {color: 'red', fontWeight: 'bold'}}>{formatStatus(sale.status)}</p>
              </ResponsiveColumn>
              <ResponsiveColumn>
                <p>{sale.productName} - {sale.parcels}x</p>
                <p><b>{sale.brand}</b></p>
              </ResponsiveColumn>
              <ResponsiveColumn style={{maxWidth: "88px"}}>
                <p>Bruto: {formatMoney(sale.value)}</p>
                <p><b>Líquido: {formatMoney(sale.liquidValue)}</b></p>
              </ResponsiveColumn>
            </ResponsiveRow>
          )
        })}
      </ResponsiveTable>
    </div>

  )
}

export default LiveTable;
