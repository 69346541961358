import React, {useCallback} from 'react';
import {ShoppingCartOutlined, CreditCardOutlined, UserOutlined} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';
import {Menu, AvatarOverlayItem} from '../styles';
import { VscLiveShare, VscDiscard  } from 'react-icons/vsc';

const PageOverlayPJ: React.FC = () => {

  const history = useHistory();
  const redirectToSaleSimulatorPage = useCallback(() => history.push('/simulador/venda'),[history]);
  const redirectToIncomeSimulatorPage = useCallback(() => history.push('/simulador/recebimento'),[history]);
  const redirectToSalesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-vendas'),[history]);
  const redirectToRecivablesPanel = useCallback(() => history.push('/tecpay/admin/painel-de-recebiveis'),[history]);


  return (
    <Menu>
      {/* Menu responsivo */}
      <AvatarOverlayItem
        icon={<VscDiscard/>}
        onClick={redirectToRecivablesPanel}
      >
        Painel de Recebiveis
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<ShoppingCartOutlined/>}
        onClick={redirectToSalesPanel}
      >
        Painel de Vendas
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToIncomeSimulatorPage}
      >
        Simulador - Recebimento
      </AvatarOverlayItem>

      <AvatarOverlayItem
        icon={<CreditCardOutlined />}
        onClick={redirectToSaleSimulatorPage}
      >
        Simulador - Venda
      </AvatarOverlayItem>
    </Menu>
  );
};
export default PageOverlayPJ;
