import React from 'react';
import {
  BarcodeOutlined,
  CarOutlined,
  CarryOutOutlined,
  FileSearchOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import {BiCopy, BiTransfer, BiTransferAlt} from 'react-icons/bi';
import { VscLiveShare } from 'react-icons/vsc';
import { RiFolderTransferLine } from 'react-icons/ri';
import { BsBoxArrowUp, BsCardList } from 'react-icons/bs';
import { GiPayMoney, GiMoneyStack } from 'react-icons/gi';
import {
  AiOutlineFilePdf,
  AiOutlineProfile,
  AiFillCreditCard,
} from 'react-icons/ai';
import { MdOutlineAccountBalanceWallet, MdOutlineRequestPage } from 'react-icons/md';
import { FaRegCreditCard, FaRegMoneyBillAlt } from 'react-icons/fa';
// import { FiUsers } from 'react-icons/fi';
import {
  Sider,
  Logo,
  TecpayLogo,
  Menu,
  SubMenu,
  Item,
  Link,
} from '../styles';
import { ISideMenuProps } from '../types';
import { RoutesEnum } from '../../../routes/types';
import {HiOutlineAdjustments} from "react-icons/hi";

const SideMenu: React.FC<ISideMenuProps> = (props: ISideMenuProps) => {
  const { collapsed } = props;
  return (
    <Sider
      width={230}
      className="site-layout-background"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{backgroundColor: '#0e2b27'}}
    >
      <Logo to={RoutesEnum.Dashboard}>
        <TecpayLogo alt="Dashboard" />
      </Logo>
      <Menu
        mode="inline"
        theme="dark"
        style={{backgroundColor: '#0e2b27'}}
      >
        <Item icon={<VscLiveShare />} key="painelVendas">
          <Link to="/tecpay/admin/painel-de-vendas">
            Vendas
          </Link>
        </Item>
        <Item icon={<VscLiveShare />} key="painelRecebiveis">
          <Link to="/tecpay/admin/painel-de-recebiveis">
            Recebíveis
          </Link>
        </Item>
      </Menu>
    </Sider>
  );
};

export default SideMenu;
