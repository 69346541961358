import React, {useEffect, useState} from "react";
import {SimuladorFormContainer, SimulatedTableContainer} from "../styles";
import SimuladorServices from "../services/SimuladorServices";
import {SimulatedParcel, SimulatorTax} from "../types";
import SimulatedTable from "../components/simulatedTable";
import {checkBandeira, checkValor, formatCurrency, formatCurrencyIntoInt} from "../utils";
import LoadingOverlay from "../../../components/LoadingOverlay";

const SimuladorVenda: React.FC = () => {
  const simuladorServices = new SimuladorServices();
  const [bandeira, setBandeira] = useState(0);
  const [valor, setValor] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [taxas, setTaxas] = useState<SimulatorTax[]>([]);
  const [valoresSimulados, setValoresSimulados] = useState<SimulatedParcel[]>([])

  const handleTaxas = async () => {
    if (checkValor(valor)  && checkBandeira(bandeira)) {
      const taxas = await simuladorServices.getTaxas(bandeira);

      if (taxas.data.response) setTaxas(taxas.data.response);
      else {
        console.log(`Erro ao obter taxas do usuário: \n${taxas.data.message}`)
        alert("Erro ao tentar executar a simulação. Tente novamente mais tarde.");
      }

    } else {
      return alert("Insira um valor válido e selecione uma bandeira para realizar a simulação.");
    }
  };

  const handleBandeira = (e: any) => {
    const stringBandeiraCod = e.target.value;
    const intBandeiraCod = parseInt(stringBandeiraCod);
    setBandeira(intBandeiraCod);
  };

  const handleValor = (e: any) => {
    const rawValue = e.target.value.replace(/\D/g, '');
    const formattedValue = formatCurrency(rawValue);
    setValor(formattedValue);
  };

  useEffect(() => {
    setIsLoading(true);
    if (taxas.length > 0) {
      const tempArray =
        taxas.map((item: any) => {
          const valorLiquido = formatCurrencyIntoInt(valor);
          const valorCliente = valorLiquido * (1 - item.juros);
          return {
            parcela: `${item.tipoPagamento} ${item.numeroParcela}x`,
            valorParcela: item.tipoPagamento === 'Debito' && (bandeira === 3 || bandeira === 9) ? '-' : valorCliente/item.numeroParcela,
            valor: item.tipoPagamento === 'Debito' && (bandeira === 3 || bandeira === 9) ? '-' : valorCliente,
          }
        })
      setValoresSimulados(tempArray);
    }
    setIsLoading(false);
  }, [taxas]);

  return (
    <div>
      <LoadingOverlay
        show={isLoading}
        relative
      />
      <SimuladorFormContainer>
        <h3>Quero vender por</h3>
        <input type="text" placeholder="Valor" value={valor} onChange={handleValor} />
        <select name="bandeira" onChange={handleBandeira}>
          <option value={0} disabled selected>Bandeira</option>
          <option value={1}>Visa</option>
          <option value={2}>Master</option>
          <option value={171}>Elo</option>
          <option value={3}>Amex</option>
          <option value={9}>Hipercard</option>
        </select>
        <button style={{borderRadius: '20px'}} onClick={handleTaxas}>Simular</button>
      </SimuladorFormContainer>
      {taxas.length > 0 && (
        <SimulatedTableContainer>
          <SimulatedTable valoresSimulados={valoresSimulados}/>
        </SimulatedTableContainer>
      )}
    </div>
  )
};

export default SimuladorVenda;
