import React from 'react';
import { withFormik } from 'formik';
import logo from '../../../assets/logo.png';
import validationSchema from './validationSchema';
import { IFormValues, IFormProps, FormProps } from './types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormField from '../../../components/Forms/Input';
import { Row, Col } from './styles';
import { Form } from '../../../styles/forms';
import { Button } from '../../../styles/buttons';
import { Link, LinkGroup } from '../../../styles/links';
import {
  Container,
  Box,
  Image,
  Span,
} from '../../../styles/_layouts/Default'

const InnerForm = (props: FormProps) => {
  const { handleSubmit, loading } = props;

  return (
    <Container>
      <Row>
        <Col>
          <Box>
            <LoadingOverlay relative show={loading} />
            <Image src={logo} alt="Logotipo Valori" />
            <Form onSubmit={handleSubmit} noValidate>
              <FormField
                name="email"
                type="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                required
              />
              <FormField
                name="password"
                type="password"
                label="Sua senha"
                placeholder="Digite sua senha"
                required
              />
              <LinkGroup style={{ textAlign: 'center' }}>
                <Span>Esqueceu sua senha? </Span>
                <Link to="/esqueci-minha-senha">Clique aqui.</Link>
              </LinkGroup>
              <LinkGroup>
                <Link to="/politica-privacidade">Politica de privacidade.</Link>
              </LinkGroup>
              <Button htmlType="submit">
                Entrar
              </Button>

            </Form>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

const SignForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    email: '',
    password: '',
  }),
  validationSchema,
  handleSubmit: async (values, { props }): Promise<void> => {
    await props.onSignInFormSubmit(values);
  },
})(InnerForm);

export default SignForm;
