import React, {ChangeEvent, useEffect, useMemo, useReducer, useRef, useState} from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import {FilterConfirmProps} from 'antd/lib/table/interface';
import {ColumnType} from 'antd/es/table';
import {Input, Popconfirm} from 'antd';
import {SearchOutlined} from '@ant-design/icons/lib';






import Select from "antd/lib/select";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import {TableContainer} from "../styles";
import Table, {TableColumn} from "../../../../components/Table";
import {DeleteOutlined, EyeOutlined} from "@ant-design/icons";
import {getAxiosInstanceLinkPagamento} from "../../../../services/providers/api";
import {formatMoney} from "../../../dashboardComercial/utils";

interface DataType {
  paymentDate: string;
  tempo: string;
  empresa: string;
  document: string;
  businessName: string;
  acquirer: string;
  terminal: string;
  tefTerminal: string;
  brand: string;
  authorizationNumber: string;
  cardNumber: string;
  productName: string;
  acquirerNsu: string;
  originalValue: string;
  status: string;
  parcels: string;
  terminalSerialNumber: string;
  liquidValue: string;
  value: string;
  merchant: string;
}

type DataIndex = keyof DataType;

const LiveTable: React.FC<any> = (props: any) => {
  const {dataFilter, setLink} = props;
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) :null;
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) :null;

  const filter = (type: string) => {
    const handleAdquirenteFilter = (value: any, record: any) => {
      return record[type].toLowerCase().includes(value.toLowerCase());
    };

    return handleAdquirenteFilter;
  };

  const handleDelete = async (id: string) => {
    setLoading(true);
    try {
      console.log(id);
      const response = await getAxiosInstanceLinkPagamento().delete(`link-pagamento/deletar/${id}`);
      if (response.status === 200) {
        getLinksDePagamento();
      }

    }catch (e) {

    }
    setLoading(false);
  }

  const getLinksDePagamento = async () => {
    const response = await getAxiosInstanceLinkPagamento().get(`link-pagamento/assinatura/listar/${selectedBusiness.merchantId}/${user.id}`);
    setLink(response.data)
  }

  const formatMoney = (value:number) => {
    const formattedValue = (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  return (
    <div>

      <LoadingOverlay
        show={loading}
        relative
      />
      <TableContainer>
        <Table
          dataSource={dataFilter}
          pagination={false}
        >
          <TableColumn title="Nome"  dataIndex="name" key="name" onFilter={filter('name')} />
          <TableColumn title="Descrição"  dataIndex="description" key="description" onFilter={filter('description')} />
          <TableColumn title="Valor" render={formatMoney}   dataIndex="value" key="value" onFilter={filter('value')} />
          <TableColumn title="URL" render={(i: any, e: any) => <a onClick={ () => window.open(e.url, '_blank') }>{e.url}</a>}  dataIndex="url" key="url" onFilter={filter('url')} />
          <TableColumn title="Expira em"   dataIndex="expires_at" key="expires_at" onFilter={filter('expires_at')} />
          <TableColumn title="Periodicidade"   dataIndex="periodicidade" key="periodicidade" onFilter={filter('periodicidade')} />
          <TableColumn title="Status"   dataIndex="status" key="status" onFilter={filter('status')} />

          <TableColumn
            title="Excluir"
            dataIndex="excluir"
            key="excluir"
            render={(i: any, e: any) =>
              <Popconfirm
                title="Deseja realmente excluir?"
                cancelText="Cancelar"
                okText="Excluir"
                onConfirm={() => handleDelete(e.link_id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            }
          />
        </Table>
      </TableContainer>
    </div>

  )
}

export default LiveTable;
