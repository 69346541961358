import styled from 'styled-components';
import { Button as AntdButton } from 'antd';
import { darken } from 'polished';

export const Button = styled(AntdButton).attrs({
  type: 'primary',
})`
  width: 100%;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.cyanDark};
  border-color: ${(props) => props.theme.colors.cyanDark};
  font-weight: 500;
  text-transform: uppercase;

  &:active, &:hover, &:focus {
    background-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
    border-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
  }
`;

export const Button2 = styled(AntdButton).attrs({
  type: 'primary',
})`
  margin-top: 2rem;
  background-color: #fbb651;
  border-color: #fbb651;
  font-weight: 500;
  text-transform: uppercase;

  &:active, &:hover, &:focus {
    background-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
    border-color: ${(props) => darken('0.2', `${props.theme.colors.cyanDark}`)};
  }
`;

export const ResetButton = styled(AntdButton).attrs({
  type: 'primary',
})`
  width: 100%;
  margin-top: 2rem;
  background-color: ${(props) => props.theme.colors.grayLight};
  border-color: ${(props) => props.theme.colors.grayLight};
  font-weight: 500;
  text-transform: uppercase;

  &:active, &:hover, &:focus {
    background-color: ${(props) => darken('0.2', `${props.theme.colors.grayLight}`)};
    border-color: ${(props) => darken('0.2', `${props.theme.colors.grayLight}`)};
  }
`;
