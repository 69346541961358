import { Dispatch } from 'react';
import { ActionTypes } from './types';
import { IPhobeusPagedResult } from '../../../../global/generics';
import {
  IAllLiveTransactions,
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../../global/dataTransferObjects/transacoesTempoReal';
import createLiveTransactionsService from '../../../../services/transacoesTempoReal';
import {toast} from "react-toastify";

export const onUpdateLoadingStatus = (status = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.UPDATE_LOADING_STATUS,
  payload: {
    status,
  },
});

export const onUpdateZeroState = (zeroState = false) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.SHOW_ZERO_STATE,
  payload: {
    zeroState,
  },
});

export const onGetLiveTransactionsTransfers = (
  allLiveTransactions: IPhobeusPagedResult<IAllLiveTransactions>,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_ALL_LIVE_TRANSACTIONS,
  payload: {
    allLiveTransactions: { ...allLiveTransactions },
  },
});

export const getAllLiveTransactions = (
  page: number,
  totalPages: number,
  filter?: PainelVendasFilterProps,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const liveTransfer = createLiveTransactionsService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await liveTransfer.getAllLiveTransactionsFiltros(page, totalPages, filter);
  if (result.status !== 200) {
    onUpdateZeroState(true)(dispatch);
    return;
  }
  onGetLiveTransactionsTransfers(result.response)(dispatch);
};

export const getAllLiveTransactionsRecebiveis = (
  page: number,
  totalPages: number,
  filter?: PainelVendasFilterProps,
  sortBy?: string
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const liveTransfer = createLiveTransactionsService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await liveTransfer.getAllLiveTransactionsRecebiveisFiltros(page, totalPages, filter, sortBy);
  if (result.status !== 200) {
    onUpdateLoadingStatus(false)(dispatch);
    toast.error('Não foi possível obter informações sobre recebíveis.');
    return;
  }
  onGetLiveTransactionsTransfers(result.response)(dispatch);
};

export const onGetFileDownload = (
  blob: any,
) => (dispatch: Dispatch<any>): void => dispatch({
  type: ActionTypes.GET_FILE_DOWNLOAD,
  payload: {
    blob,
  },
});

export const getFileDownload = (
  page: number,
  totalPages: number,
  filter?: PainelVendasFilterProps,
  url?: string,
) => async (dispatch: Dispatch<any>): Promise<void> => {
  const liveTransfer = createLiveTransactionsService();
  onUpdateLoadingStatus(true)(dispatch);

  const result = await liveTransfer.getFileDownload(page, totalPages, filter, url);

  try {
    const objectUrl = window.URL.createObjectURL(result);
    const a = document.createElement('a');
    a.href = objectUrl;

    if (url === 'receivables-xls') a.download = 'data.xlsx';
    else  a.download = 'data.csv';

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);

  } catch (error) {
    console.error('Erro ao exportar tabela:', error);
  }
  onGetFileDownload(result)(dispatch);
};
