import React from "react";
import {CardProps} from "../../types";
import {CardContainer, IconCircle} from "../../styles";
import {formatMoney} from "../../utils";
import {TagOutlined, FireOutlined, ShoppingCartOutlined, CreditCardOutlined} from "@ant-design/icons";

const Card: React.FC<CardProps> = (props) => {
  const {cardData,
    businessNumber,
    tpv,
    averageTicket,
    terminalsNumber} = props;


  return (
    <div style={{display: 'flex', width:'50%', gap:'2' +
        'rem', flexDirection:"column", maxHeight:'fit-content'}}>
      <CardContainer>
        <div style={{display: 'flex'}}>
          <IconCircle style={{backgroundColor: 'rgba(170,255,244,0.5)'}}>
            <TagOutlined
              style={{
                fontSize: 18,
                color: 'rgb(0,255,222)'
              }}/>
          </IconCircle>
          <p style={{fontSize: '25px', marginTop: '0'}}>
            TPV
          </p>
        </div>
        <p style={{fontSize: '30px', fontWeight: 'bold'}}>{formatMoney(cardData.totalGross)}</p>
      </CardContainer>

      <CardContainer>
        <div style={{display: "flex"}}>
          <IconCircle style={{backgroundColor: 'rgba(251,182,81,0.50)'}}>
            <FireOutlined
              style={{ fontSize: 18,
                color: 'rgb(255,152,0)'
              }}
            />
          </IconCircle>
          <p style={{fontSize: '25px', marginTop: '0'}}>
            Ativos
          </p>
        </div>
        <p style={{fontSize: '30px', fontWeight: 'bold'}}>{businessNumber}</p>
      </CardContainer>

      <CardContainer>
        <div style={{display: 'flex'}}>
          <IconCircle style={{backgroundColor: 'rgba(81,251,42,0.5)'}}>
            <ShoppingCartOutlined
              style={{
                fontSize: 18,
                color: 'rgb(0,255,42)'
              }}
            />
          </IconCircle>
          <p style={{fontSize: '25px', marginTop: '0'}}>
            Ticket Médio
          </p>
        </div>
        <p style={{fontSize: '30px', fontWeight: 'bold'}}>{formatMoney(cardData.averageTicket)}</p>
      </CardContainer>
      {/*POS*/}
      <CardContainer>
        <div style={{display: 'flex'}}>
          <IconCircle style={{backgroundColor: 'rgba(104,81,251,0.5)'}}>
            <CreditCardOutlined
              style={{
                fontSize: 18,
                color: 'rgb(34,0,255)'
              }}
            />
          </IconCircle>
          <p style={{fontSize: '25px', marginTop: '0'}}>
            Qtd POS
          </p>
        </div>
        <p style={{fontSize: '30px', fontWeight: 'bold'}}>{terminalsNumber}</p>
      </CardContainer>
    </div>
  )
};

export default Card;
