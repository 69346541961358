import {Pie} from 'react-chartjs-2';
import React from "react";
import {PieChartProps} from "./types";

const PieChart: React.FC<PieChartProps> = (props: PieChartProps) => {
  const {chartName, chartData, chartOptions} = props;
  return (
    <div className="chart-container">
      <h2 style={{textAlign: "center"}}>{chartName}</h2>
      <Pie
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
};

export default PieChart;
