import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { RadioChangeEvent } from 'antd';
import { toast } from 'react-toastify';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { schema } from './schema';
import { IFormValues, IFormProps, FormProps } from './types';
import FormField from '../../../../../../components/Forms/Input';
import {
  Row,
  Col,
  Radio,
  RadioGroup,
} from './styles';
import { InputGroup } from '../../../../../../styles/inputs';
import { Form } from '../../../../../../styles/forms';
import { Button } from '../../../../../../styles/buttons';
import LoadingOverlay from '../../../../../../components/LoadingOverlay';
import createViaCepService from '../../../../../../services/viaCep';

const InnerForm = (props: FormProps) => {
  const [userType, setUserType] = useState<number>(1);
  const [phoneMask, setPhoneMask] = useState<string>('(99) 9999-99999');
  const {
    handleSubmit,
    loading,
    setFieldValue,
    values,
  } = props;

  useEffect(() => {
    const handleGetAddress = async (): Promise<void> => {
      const formattedCep = values.cep?.replace(/[^0-9]/g, '');
      if (formattedCep?.length !== 8) {
        return;
      }

      const viaCepService = createViaCepService();
      const data = await viaCepService.getAddressByCep(formattedCep);
      if (data.erro === true) {
        toast.error('CEP do endereço inválido!')
      }

      setFieldValue('logradouro', data.logradouro);
      setFieldValue('complemento', data.complemento);
      setFieldValue('bairro', data.bairro);
      setFieldValue('cidade', data.localidade);
      setFieldValue('uf', data.uf);
    };

    handleGetAddress();
  }, [values.cep]);

  const handleChangeUserType = (e: RadioChangeEvent): void => {
    setUserType(e.target.value);
    setFieldValue('userType', e.target.value)
  };

  useEffect(() => {
    const handlePhoneNumber = () => {
      const formattedPhoneNumber = values.phoneNumber?.replace(/[^0-9]/g, '');
      if (formattedPhoneNumber?.length <= 10) {
        setPhoneMask('(99) 9999-99999');
        return;
      }

      setPhoneMask('(99) 99999-9999');
    }

    handlePhoneNumber();
  }, [values.phoneNumber]);

  return (
    <Row>
      <Col>
        <LoadingOverlay relative show={loading} />
        <Form onSubmit={handleSubmit} noValidate>
          <RadioGroup onChange={handleChangeUserType} value={userType}>
            <Radio name="userType" value={1}>Pessoa física</Radio>
            <Radio name="userType" value={2}>Pessoa jurídica</Radio>
            <Radio name="userType" value={3}>Administrador</Radio>
            <Radio name="userType" value={4}>Antecipadora</Radio>
            <Radio name="userType" value={6}>Comercial</Radio>
          </RadioGroup>
          {userType === 1 || userType === 3 || userType === 6
            ? (
              <>
                <FormField
                  name="documentNumber"
                  type="text"
                  label="CPF"
                  placeholder="Digite seu CPF"
                  mask="999.999.999-99"
                  required
                />
                <FormField
                  name="birthDate"
                  type="date"
                  label="Data de nascimento"
                  placeholder="Digite a data de nascimento"
                  required
                />
              </>
            )
            : (
              <>
                <FormField
                  name="companyName"
                  type="text"
                  label="Nome fantasia"
                  placeholder="Digite o nome fantasia da empresa"
                  required
                />
                <FormField
                  name="tradingName"
                  type="text"
                  label="Razão social"
                  placeholder="Digite a razão social da empresa"
                  required
                />
                <FormField
                  name="documentNumber"
                  type="text"
                  label="CNPJ"
                  placeholder="Digite seu CNPJ"
                  mask="99.999.999/9999-99"
                  required
                />
              </>
            )}
          <InputGroup>
            <FormField
              name="name"
              type="text"
              label="Nome"
              placeholder="Digite seu nome"
              required
            />
            <FormField
              name="lastName"
              type="text"
              label="Sobrenome"
              placeholder="Digite seu sobrenome"
              required
            />
          </InputGroup>
          <FormField
            name="email"
            type="email"
            label="E-mail"
            placeholder="Digite seu e-mail"
            required
          />
          <FormField
            name="phoneNumber"
            type="text"
            label="Número do telefone"
            placeholder="Digite o número do telefone"
            mask={phoneMask}
            required
          />
          <FormField
            name="cep"
            type="text"
            label="CEP"
            placeholder="Digite o CEP do seu endereço"
            mask="99999-999"
            required
          />
          <FormField
            name="logradouro"
            type="text"
            label="Endereço"
            placeholder="Digite seu endereço"
            required
          />
          <InputGroup>
            <FormField
              name="numero"
              type="text"
              label="Número"
              placeholder="Digite o número"
              required
            />
            <FormField
              name="complemento"
              type="text"
              label="Complemento"
              placeholder="Digite o complemento"
            />
          </InputGroup>
          <InputGroup>
            <FormField
              name="bairro"
              type="text"
              label="Bairro"
              placeholder="Digite o bairro"
            />
            <FormField
              name="cidade"
              type="text"
              label="Cidade"
              placeholder="Digite o nome da cidade"
            />
          </InputGroup>
          <FormField
            name="uf"
            type="text"
            label="Estado"
            required
            disabled
          />
          <Button htmlType="submit">
            Cadastrar
          </Button>
        </Form>
      </Col>
    </Row>
  )
}

const SignForm = withFormik<IFormProps, IFormValues>({
  mapPropsToValues: () => ({
    name: '',
    lastName: '',
    companyName: '',
    tradingName: '',
    email: '',
    documentNumber: '',
    phoneNumber: '',
    birthDate: '',
    userType: 1,
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
  }),
  validationSchema: schema,
  handleSubmit: async (values, { props }): Promise<void> => {
    const documentNumber = values.documentNumber.replace(/[^0-9]/g, '');
    if (!(cpf.isValid(documentNumber) || cnpj.isValid(documentNumber))) {
      toast.error('Número de documento inválido!');
      return;
    }

    const phoneNumber = values.phoneNumber.replace(/[^0-9]/g, '');
    const cep = values.cep.replace(/[^0-9]/g, '');
    const formattedValues = {
      ...values, documentNumber, phoneNumber, cep,
    };

    await props.onSignUpFormSubmit(formattedValues);
  },
})(InnerForm);

export default SignForm;
