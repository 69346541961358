import {getAxiosInstance} from "../../../services/providers/api";
import {FilterData} from "../types";

export default class DashboardComercialServices {
  getVendas = async (filter: FilterData) => {
    const paged = true;
    const pageSize = 100;
    const offSet = 0;
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;

    let status = null;
    if (filter.Status === '0') status = 0;
    else status = filter.Status;

    return getAxiosInstance().post('/transacoes/sales-panel', {
      offSet: offSet,
      PageSize: pageSize,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      Status: status,
      Paged: false
    });
  };

  getDashboardTotals = async (filter: FilterData) => {
    const paged = true;
    const pageSize = 100;
    const offSet = 0;
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;

    let status = null;
    if (filter.Status === '0') status = 0;
    else status = filter.Status;

    return getAxiosInstance().post('/transacoes/dashboard-totals', {
      offSet: offSet,
      PageSize: pageSize,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      Status: status,
      Paged: true
    });
  };


  getBusiness = async () => {
    return getAxiosInstance().get('/payments/business', {
      offset: 0,
      pageSize: 5000
    })
  };

  getRecebiveis = async (filter: FilterData) => {
    const dateInicial = filter?.InitDate || null;
    const dateFinal = filter?.FinishDate || null;
    const status = filter?.Status || null;
    return getAxiosInstance().post('/transacoes/receivables', {
      offSet: 0,
      PageSize: 20,
      InitDate: dateInicial,
      FinishDate: dateFinal,
      StatusPagamento: status,
      Paged: false,
    })
  }
}
