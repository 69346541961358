import styled from 'styled-components'
import { darken, linearGradient } from 'polished';
import {
  Row as AntdRow,
  Col as AntdCol,
} from 'antd'

export const Row = styled(AntdRow).attrs({
  justify: 'center',
  align: 'middle',
})``;

export const Col = styled(AntdCol).attrs({
  lg: { span: 6 },
})``;

export const Container = styled.div`
  height: auto;
  min-height: 100vh;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${(props) => linearGradient({
    colorStops: [
      `${props.theme.colors.cyanDark}`,
      `${darken('0.2', `${props.theme.colors.cyanDark}`)}`,
    ],
  })}
`;

export const Box = styled.div`
  width: 900px;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${(props) => `0 0 20px ${props.theme.colors.blueCyanDarkest}`};
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    width: 370px;
    padding: 12px;
    border-radius: 12px;
    justify-self: center;
  }
`;

export const Title = styled.text`
  font-size: 28px;
  font-weight: bold;
  color: #215868;
  text-align: center;

  @media (max-width: 450px) {
    font-size: 24px;
    padding-bottom: 24px;
  }
`;

export const Text = styled.text`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #211915;
  text-align: justify;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const TextItem = styled.text`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  font-size: 16px;
  color: #211915;
  text-align: justify;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const TextBold = styled.text`
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #211915;
  text-align: justify;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;

export const Index = styled.text`
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 35px;
  color: #211915;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 12px;
  }
`;
