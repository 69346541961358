import {getAxiosInstance} from "../../../services/providers/api";

export default class SimuladorServices {
  getTaxas = async (bandeira: number) => {

    return getAxiosInstance().post('/transacoes/simulate-pay', {
      Bandeira: bandeira,
    })
  }
}

