import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';

import AuthGatePessoaFisica from '../components/AuthGatePessoaFisica';
import AuthGatePessoaJuridica from '../components/AuthGatePessoaJuridica';
import AuthGateAdmin from '../components/AuthGateAdmin';
import PrivateRoute from '../components/PrivateRoute';
import Dashboard from '../pages/dashboard';
import SignIn from '../pages/signIn';
import signInAdmin from '../pages/signinAdmin';
import SignUp from '../pages/SignUp';
import PageWrapperPessoaFisica from '../components/PageWrapperPessoaFisica';
import PageWrapperPessoaJuridica from '../components/PageWrapperPessoaJuridica';
import PageWrapperAdmin from '../components/PageWrapperAdmin';
import AuthGateAntecipadora from '../components/AuthGateAntecipadora';
import NotFound from '../pages/NotFound';
import Veiculos from '../pages/veiculos';
import ConsultaSolicitacoes from '../pages/DebitosVeiculares/consultaSolicitacoes/list';
import ConsultaDebitos from '../pages/DebitosVeiculares/ConsultaDebitos';
import ConsultaMultasRenainf from '../pages/DebitosVeiculares/consultaMultasRenainf';
import ForgotPassword from '../pages/forgotPassword';
import ResetPassword from '../pages/resetPassword';
import PoliticaPrivacidade from '../pages/privacyPolicy';
import CadastrarConsulta from '../pages/antecipacao/cadastrarConsulta';
import UnidadesRecebiveis from '../pages/cerc/unidadesRecebiveis';
import ResetPasswordApp from '../pages/resetPasswordApp';
import Usuarios from '../pages/usuarios';
import TransacoesNossaCidade from '../pages/transacoesNossaCidade';
import Cancellations from '../pages/creditCard/cancellation';
import Chargeback from '../pages/creditCard/chargeback';
import Vendas from '../pages/vendas';
import Adiantamentos from '../pages/adiantamentosNossaCidade/adiantamentos';
import NotaPromissoria from '../pages/notaPromissoria';
import Credenciais from '../pages/credenciais';
import Business from '../pages/business';
import {RoutesEnum} from './types';
import Detalhes from '../pages/transacoesErepasses/details';
import BoletoCnab from '../pages/boletoRendimento/gerarBoletoCnab';
import ListaBoletos from '../pages/boletoRendimento/boletos';
import TransacaoTempoReal from '../pages/transacoesErepasses/transacaoTempoReal';
import Adjustment from '../pages/transacoesErepasses/adjustments';
import Repasses from '../pages/transacoesErepasses/repasses';
import DetalhesRepasses from '../pages/transacoesErepasses/detalhesRepasses';
import TransferenciasRepasses from '../pages/transacoesErepasses/transferenciasDeRepasses'
import CarteiraVirtual from '../pages/carteiraVirtual';
import CreatePoliticaCobranca from '../pages/politicaCobranca/create';
import ListPoliticaCobranca from '../pages/politicaCobranca/list';
import UpdatePoliticaCobranca from '../pages/politicaCobranca/update';
import PoliticaContaDigitalList from '../pages/politicaContaDigital/list';
import PoliticaContaDigitalCreate from '../pages/politicaContaDigital/create';
import PoliticaContaDigitalUpdate from '../pages/politicaContaDigital/update';
import ListaEmpresa from '../pages/listaEmpresas';
import Arranjos from '../pages/arranjos/arranjos';
import useCurrentBusiness from '../hooks/useCurrentBusiness';
import PixCopiaECola from '../pages/pix/copiaECola';
import PoliticaCobrancaEmpresa from '../pages/politicaCobranca/list/components/business';
import PixPagamentos from '../pages/pix/pagamento';
import Extrato from '../pages/contaDigital/extrato';
import BoletoPagamento from '../pages/boletoRendimento/pagamento';
import Trava from '../pages/contaEscrow/trava/trava';
import PageWrapperAntecipadora from '../components/PageWrapperAntecipadora';
import CargaCartao from '../pages/cargaCartao/pagamento';
import ExtratoCartao from '../pages/cargaCartao/extrato/extratoCartao';
import CartaoProxy from '../pages/cartaoProxy/cartaoProxy';
import MeuPerfil from '../pages/meuPerfil';
import ContratoEscrow from '../pages/contratoEscrow';
import LinkBusiness from '../pages/linkBusiness/linkBusiness'
import NewBusiness from '../pages/business/newBusiness/newBusiness';
import PainelDeVendas from '../pages/transacoesErepasses/painelDeVendas/painelDeVendas';
import PainelDeRecebiveis from "../pages/transacoesErepasses/painelDeRecebiveis/painelDeRecebiveis";
import PainelDePagamentos from "../pages/transacoesErepasses/painelDePagamentos";
import SimuladorVenda from "../pages/simuladores/simuladorVenda";
import SimuladorRecebimento from "../pages/simuladores/simuladorRecebimento";
import AuthGateComercial from "../components/AuthGateComercial";
import PageWrapperComercial from "../components/PageWrapperComercial";
import DashboardComercial from "../pages/dashboardComercial";
import ListarLinks from "../pages/linkDePagamento/listar-links/listar-links";
import CadastrarLinks from "../pages/linkDePagamento/cadastrar-links/cadastrar-links";
import ListarAssinaturas from "../pages/linkDeAssinatura/listar-links/listar-assinaturas";
import CadastrarAssinaturas from "../pages/linkDeAssinatura/cadastrar-links/cadastrar-assinaturas";
import Terminais from "../pages/terminais";
// import UsuariosAntecipadoras from '../pages/antecipacao/listarUsuarios/usuariosAntecipadora';

const Routes: React.FC = () => {
  const currentBusiness = useCurrentBusiness();
  const selectedBusiness = localStorage.getItem('selectedCompany') ? JSON.parse(localStorage.getItem('selectedCompany') as string) :null;
  const [business, setBusiness] = useState<boolean>(false);

  const LoginRedirect = () => <Redirect to="/login" />

  useEffect(() => {
    const handleBusiness = () => {

      if (selectedBusiness == null) {
        setBusiness(true);
      } else {
        setBusiness(false);
      }
    }
    handleBusiness();
  }, [selectedBusiness]);
  return (
    <Router>
      <Switch>
        <Route exact path="/cadastro" component={SignUp} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/login/suporte/:email" component={signInAdmin} />
        <Route exact path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route exact path="/redefinir-senha" component={ResetPassword} />
        <Route exact path="/politica-privacidade" component={PoliticaPrivacidade} />
        <Route exact path="/reset-password-app" component={ResetPasswordApp} />
        <Route path="/" component={LoginRedirect}>

          <AuthGatePessoaFisica>
            <PageWrapperPessoaFisica>
              <PrivateRoute exact path="/">
                <Redirect to="/tecpay/admin/painel-de-vendas" />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/veiculos" component={Veiculos} />
              <PrivateRoute exact path="/arranjos" component={Arranjos} />
              <PrivateRoute exact path="/debitos-veiculares/pagamentos" component={ConsultaSolicitacoes} />
              <PrivateRoute exact path="/debitos-veiculares/debitos" component={ConsultaDebitos} />
              <PrivateRoute exact path="/debitos-veiculares/multas-renainf" component={ConsultaMultasRenainf} />
              <PrivateRoute exact path="/boletos/pagamentos" component={BoletoPagamento} />
              {/* <PrivateRoute exact path="/repasses" component={Repasses} /> */}
              {/* <PrivateRoute exact path="/repasses/detalhes" component={DetalhesRepasses} /> */}
              <PrivateRoute exact path="/transacoes-tempo-real" component={TransacaoTempoReal} />
              <PrivateRoute exact path="/transacoes-repasse" component={Detalhes} />
              <PrivateRoute exact path="/pix/copia-e-cola" component={PixCopiaECola} />
              <PrivateRoute exact path="/pix/pagamentos" component={PixPagamentos} />
              <PrivateRoute exact path="/conta-digital/extrato" component={Extrato} />
              <PrivateRoute exact path="/tecpay/admin/repasses" component={Repasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/detalhes" component={DetalhesRepasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/transferencias" component={TransferenciasRepasses} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-vendas" component={PainelDeVendas} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-recebiveis" component={PainelDeRecebiveis} />
              <PrivateRoute exact path="/tecpay/admin/carteira-virtual" component={CarteiraVirtual} />
              <PrivateRoute exact path="/conta-digital/carga-cartao" component={CargaCartao} />
              <PrivateRoute exact path="/conta-digital/extrato-cartao" component={ExtratoCartao} />
              <PrivateRoute exact path="/meu-perfil" component={MeuPerfil} />
              <PrivateRoute exact path="/simulador/venda" component={SimuladorVenda} />
              <PrivateRoute exact path="/simulador/recebimento" component={SimuladorRecebimento} />
            </PageWrapperPessoaFisica>
          </AuthGatePessoaFisica>

          <AuthGatePessoaJuridica>
            {business
              && (
                <>
                  <PrivateRoute exact path="/">
                    <Redirect to="/tecpay/lista-empresa" />
                  </PrivateRoute>
                  <PrivateRoute exact path="/tecpay/lista-empresa" component={ListaEmpresa} />
                </>
              )}
            {!business
              && (
                <PageWrapperPessoaJuridica>
                  <PrivateRoute exact path="/tecpay/lista-empresa">
                    <Redirect to="/tecpay/admin/painel-de-vendas" />
                  </PrivateRoute>
                  <PrivateRoute exact path="/">
                    <Redirect to="/tecpay/admin/painel-de-vendas" />
                  </PrivateRoute>
                  <PrivateRoute exact path="/dashboard" component={Dashboard} />
                  <PrivateRoute exact path="/dashboard/:id" component={Dashboard} />
                  <PrivateRoute exact path="/veiculos" component={Veiculos} />
                  <PrivateRoute exact path="/debitos-veiculares/pagamentos" component={ConsultaSolicitacoes} />
                  <PrivateRoute exact path="/debitos-veiculares/debitos" component={ConsultaDebitos} />
                  <PrivateRoute exact path="/debitos-veiculares/multas-renainf" component={ConsultaMultasRenainf} />
                  <PrivateRoute exact path="/boletos" component={ListaBoletos} />
                  <PrivateRoute exact path="/boletos/pagamentos" component={BoletoPagamento} />
                  <PrivateRoute exact path="/boletos/gerar-boleto/cnab" component={BoletoCnab} />
                  <PrivateRoute exact path="/consultas/cadastrar-consulta" component={CadastrarConsulta} />
                  {/* <PrivateRoute exact path="/repasses" component={Repasses} /> */}
                  {/* <PrivateRoute exact path="/repasses/detalhes" component={DetalhesRepasses} /> */}

                  {selectedBusiness?.allowPaymentLink && (
                    <>
                    <PrivateRoute exact path="/listar-links" component={ListarLinks} />
                    <PrivateRoute exact path="/cadastrar-links" component={CadastrarLinks} />
                    </>

                  )}


                  {selectedBusiness?.allowPaymentLink && (
                    <>
                      <PrivateRoute exact path="/listar-assinaturas" component={ListarAssinaturas} />
                      <PrivateRoute exact path="/cadastrar-assinaturas" component={CadastrarAssinaturas} />
                    </>

                  )}


                  <PrivateRoute exact path="/transacoes-tempo-real" component={TransacaoTempoReal} />
                  <PrivateRoute exact path="/transacoes-repasse" component={Detalhes} />
                  <PrivateRoute
                    exact
                    path={`${RoutesEnum.UnidadesRecebiveis}/:solcitacaoConsultaId`}
                    component={UnidadesRecebiveis}
                  />
                  <PrivateRoute exact path="/pix/copia-e-cola" component={PixCopiaECola} />
                  <PrivateRoute exact path="/tecpay/admin/repasses" component={Repasses} />
                  <PrivateRoute exact path="/tecpay/admin/repasses/detalhes" component={DetalhesRepasses} />
                  <PrivateRoute exact path="/tecpay/admin/repasses/transferencias" component={TransferenciasRepasses} />
                  <PrivateRoute exact path="/tecpay/admin/painel-de-vendas" component={PainelDeVendas} />
                  <PrivateRoute exact path="/tecpay/admin/painel-de-recebiveis" component={PainelDeRecebiveis} />
                  <PrivateRoute exact path="/tecpay/admin/carteira-virtual" component={CarteiraVirtual} />
                  <PrivateRoute exact path="/pix/pagamentos" component={PixPagamentos} />
                  <PrivateRoute exact path="/conta-digital/extrato" component={Extrato} />
                  <PrivateRoute exact path="/conta-digital/carga-cartao" component={CargaCartao} />
                  <PrivateRoute exact path="/conta-digital/extrato-cartao" component={ExtratoCartao} />
                  <PrivateRoute exact path="/meu-perfil" component={MeuPerfil} />
                  <PrivateRoute exact path="/simulador/venda" component={SimuladorVenda} />
                  <PrivateRoute exact path="/simulador/recebimento" component={SimuladorRecebimento} />
                </PageWrapperPessoaJuridica>
              )}
          </AuthGatePessoaJuridica>

          <AuthGateAntecipadora>
            <PageWrapperAntecipadora>
              <PrivateRoute exact path="/">
                <Redirect to="/tecpay/admin/painel-de-vendas" />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/veiculos" component={Veiculos} />
              <PrivateRoute exact path="/arranjos" component={Arranjos} />
              <PrivateRoute exact path="/debitos-veiculares/pagamentos" component={ConsultaSolicitacoes} />
              <PrivateRoute exact path="/debitos-veiculares/debitos" component={ConsultaDebitos} />
              <PrivateRoute exact path="/debitos-veiculares/multas-renainf" component={ConsultaMultasRenainf} />
              <PrivateRoute exact path="/boletos" component={ListaBoletos} />
              <PrivateRoute exact path="/boletos/pagamentos" component={BoletoPagamento} />
              <PrivateRoute exact path="/boletos/gerar-boleto/cnab" component={BoletoCnab} />
              <PrivateRoute exact path="/consultas/cadastrar-consulta" component={CadastrarConsulta} />
              {/* <PrivateRoute exact path="/usuarios/usuarios-vinculados" component={UsuariosAntecipadoras} /> */}
              {/* <PrivateRoute exact path="/repasses" component={Repasses} /> */}
              {/* <PrivateRoute exact path="/repasses/detalhes" component={DetalhesRepasses} /> */}
              <PrivateRoute exact path="/transacoes-tempo-real" component={TransacaoTempoReal} />
              <PrivateRoute exact path="/transacoes-repasse" component={Detalhes} />
              <PrivateRoute exact path="/tecpay/admin/repasses" component={Repasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/detalhes" component={DetalhesRepasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/transferencias" component={TransferenciasRepasses} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-vendas" component={PainelDeVendas} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-recebiveis" component={PainelDeRecebiveis} />
              <PrivateRoute exact path="/tecpay/admin/carteira-virtual" component={CarteiraVirtual} />
              <PrivateRoute
                exact
                path={`${RoutesEnum.UnidadesRecebiveis}/:solcitacaoConsultaId`}
                component={UnidadesRecebiveis}
              />
              <PrivateRoute exact path="/pix/copia-e-cola" component={PixCopiaECola} />
              <PrivateRoute exact path="/pix/pagamentos" component={PixPagamentos} />
              <PrivateRoute exact path="/conta-digital/extrato" component={Extrato} />
              <PrivateRoute exact path="/conta-escrow-trava" component={Trava} />
              <PrivateRoute exact path="/meu-perfil" component={MeuPerfil} />
              <PrivateRoute exact path="/contrato-escrow" component={ContratoEscrow} />
            </PageWrapperAntecipadora>
          </AuthGateAntecipadora>

          <AuthGateAdmin>
            <PageWrapperAdmin>
              <PrivateRoute exact path="/">
                <Redirect to="/tecpay/admin/painel-de-vendas" />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/comercial/dashboard" component={DashboardComercial} />
              <PrivateRoute exact path="/tecpay/admin/usuarios" component={Usuarios} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-nossa-cidade" component={TransacoesNossaCidade} />
              <PrivateRoute exact path="/tecpay/admin/cancelamentos" component={Cancellations} />
              <PrivateRoute exact path="/tecpay/admin/chargebacks" component={Chargeback} />
              <PrivateRoute exact path="/tecpay/admin/vendas" component={Vendas} />
              <PrivateRoute exact path="/tecpay/admin/notaPromissoria" component={NotaPromissoria} />
              <PrivateRoute exact path="/veiculos" component={Veiculos} />
              <PrivateRoute exact path="/arranjos" component={Arranjos} />
              <PrivateRoute exact path="/debitos-veiculares/pagamentos" component={ConsultaSolicitacoes} />
              <PrivateRoute exact path="/debitos-veiculares/debitos" component={ConsultaDebitos} />
              <PrivateRoute exact path="/debitos-veiculares/multas-renainf" component={ConsultaMultasRenainf} />
              <PrivateRoute exact path="/tecpay/admin/adiantamentos" component={Adiantamentos} />
              <PrivateRoute exact path="/tecpay/admin/credenciais/criar-credencial" component={Credenciais} />
              <PrivateRoute exact path="/tecpay/admin/empresas" component={Business} />
              <PrivateRoute exact path="/tecpay/admin/vincular-empresa" component={LinkBusiness} />
              <PrivateRoute exact path="/tecpay/admin/empresas/novaEmpresa" component={NewBusiness} />
              <PrivateRoute exact path="/boletos" component={ListaBoletos} />
              <PrivateRoute exact path="/boletos/pagamentos" component={BoletoPagamento} />
              <PrivateRoute exact path="/boletos/gerar-boleto/cnab" component={BoletoCnab} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-tempo-real" component={TransacaoTempoReal} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-repasse" component={Detalhes} />
              <PrivateRoute exact path="/tecpay/admin/ajustes" component={Adjustment} />
              <PrivateRoute exact path="/tecpay/admin/repasses" component={Repasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/detalhes" component={DetalhesRepasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/transferencias" component={TransferenciasRepasses} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-vendas" component={PainelDeVendas} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-recebiveis" component={PainelDeRecebiveis} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-pagamentos" component={PainelDePagamentos} />
              <PrivateRoute exact path="/tecpay/admin/carteira-virtual" component={CarteiraVirtual} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/cadastrar" component={CreatePoliticaCobranca}/>
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca" component={ListPoliticaCobranca} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/editar/:politicaCobrancaId" component={UpdatePoliticaCobranca}/>
              <PrivateRoute exact path="/pix/copia-e-cola" component={PixCopiaECola} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/business" component={PoliticaCobrancaEmpresa}/>
              <PrivateRoute exact path="/pix/pagamentos" component={PixPagamentos} />
              <PrivateRoute exact path="/conta-digital/extrato" component={Extrato} />
              <PrivateRoute exact path="/conta-digital/carga-cartao" component={CargaCartao} />
              <PrivateRoute exact path="/conta-digital/extrato-cartao" component={ExtratoCartao} />
              <PrivateRoute exact path="/conta-digital/proxy" component={CartaoProxy} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas" component={PoliticaContaDigitalList} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas/cadastrar" component={PoliticaContaDigitalCreate} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas/editar/:politicaCobrancaId" component={PoliticaContaDigitalUpdate}/>
              <PrivateRoute exact path="/simulador/venda" component={SimuladorVenda} />
              <PrivateRoute exact path="/simulador/recebimento" component={SimuladorRecebimento} />
              <PrivateRoute exact path="/terminais" component={Terminais} />
            </PageWrapperAdmin>
          </AuthGateAdmin>

          <AuthGateComercial>
            <PageWrapperComercial>
              <PrivateRoute exact path="/">
                <Redirect to="/tecpay/admin/painel-de-vendas" />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              {/* <PrivateRoute exact path="/comercial/dashboard" component={DashboardComercial} /> */}
              <PrivateRoute exact path="/tecpay/admin/usuarios" component={Usuarios} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-nossa-cidade" component={TransacoesNossaCidade} />
              <PrivateRoute exact path="/tecpay/admin/cancelamentos" component={Cancellations} />
              <PrivateRoute exact path="/tecpay/admin/chargebacks" component={Chargeback} />
              <PrivateRoute exact path="/tecpay/admin/vendas" component={Vendas} />
              <PrivateRoute exact path="/tecpay/admin/notaPromissoria" component={NotaPromissoria} />
              <PrivateRoute exact path="/veiculos" component={Veiculos} />
              <PrivateRoute exact path="/arranjos" component={Arranjos} />
              <PrivateRoute exact path="/debitos-veiculares/pagamentos" component={ConsultaSolicitacoes} />
              <PrivateRoute exact path="/debitos-veiculares/debitos" component={ConsultaDebitos} />
              <PrivateRoute exact path="/debitos-veiculares/multas-renainf" component={ConsultaMultasRenainf} />
              <PrivateRoute exact path="/tecpay/admin/adiantamentos" component={Adiantamentos} />
              <PrivateRoute exact path="/tecpay/admin/credenciais/criar-credencial" component={Credenciais} />
              <PrivateRoute exact path="/tecpay/admin/empresas" component={Business} />
              <PrivateRoute exact path="/tecpay/admin/vincular-empresa" component={LinkBusiness} />
              <PrivateRoute exact path="/tecpay/admin/empresas/novaEmpresa" component={NewBusiness} />
              <PrivateRoute exact path="/boletos" component={ListaBoletos} />
              <PrivateRoute exact path="/boletos/pagamentos" component={BoletoPagamento} />
              <PrivateRoute exact path="/boletos/gerar-boleto/cnab" component={BoletoCnab} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-tempo-real" component={TransacaoTempoReal} />
              <PrivateRoute exact path="/tecpay/admin/transacoes-repasse" component={Detalhes} />
              <PrivateRoute exact path="/tecpay/admin/ajustes" component={Adjustment} />
              <PrivateRoute exact path="/tecpay/admin/repasses" component={Repasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/detalhes" component={DetalhesRepasses} />
              <PrivateRoute exact path="/tecpay/admin/repasses/transferencias" component={TransferenciasRepasses} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-vendas" component={PainelDeVendas} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-recebiveis" component={PainelDeRecebiveis} />
              <PrivateRoute exact path="/tecpay/admin/painel-de-pagamentos" component={PainelDePagamentos} />
              <PrivateRoute exact path="/tecpay/admin/carteira-virtual" component={CarteiraVirtual} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/cadastrar" component={CreatePoliticaCobranca}/>
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca" component={ListPoliticaCobranca} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/editar/:politicaCobrancaId" component={UpdatePoliticaCobranca}/>
              <PrivateRoute exact path="/pix/copia-e-cola" component={PixCopiaECola} />
              <PrivateRoute exact path="/tecpay/admin/cadastro-geral/politicas-cobranca/business" component={PoliticaCobrancaEmpresa}/>
              <PrivateRoute exact path="/pix/pagamentos" component={PixPagamentos} />
              <PrivateRoute exact path="/conta-digital/extrato" component={Extrato} />
              <PrivateRoute exact path="/conta-digital/carga-cartao" component={CargaCartao} />
              <PrivateRoute exact path="/conta-digital/extrato-cartao" component={ExtratoCartao} />
              <PrivateRoute exact path="/conta-digital/proxy" component={CartaoProxy} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas" component={PoliticaContaDigitalList} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas/cadastrar" component={PoliticaContaDigitalCreate} />
              <PrivateRoute exact path="/contas-digitais/politicas-cobrancas/editar/:politicaCobrancaId" component={PoliticaContaDigitalUpdate}/>
              <PrivateRoute exact path="/simulador/venda" component={SimuladorVenda} />
              <PrivateRoute exact path="/simulador/recebimento" component={SimuladorRecebimento} />
              <PrivateRoute exact path="/terminais" component={Terminais} />
            </PageWrapperComercial>
          </AuthGateComercial>

        </Route>
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  )
};

export default Routes;
