import React, {useEffect, useState} from 'react';
import { withFormik } from 'formik';
import {toast} from "react-toastify";
import {Row, Col, Checkbox} from 'antd';
import Select from 'antd/lib/select';
import schema from './schema';
import DatePickerField from '../../../../../components/DatePicker';
import {
  DateView,
  Button,
  Form,
  Label,
  ValueCard,
  FilterRow,
  FilterContainer,
} from './styles';
import {
  ILiveTransactionsFilterProps,
  PainelVendasFilterProps,
} from '../../../../../global/dataTransferObjects/transacoesTempoReal';
import { FormProps, IFormProps } from '../../types';
import { formatDate } from '../../../../../utils/formatDate';
import useGetMe from '../../../../../hooks/useCurrentUser'

const FormFilter: React.FC<FormProps> = (props: FormProps) => {
  const user = useGetMe();
  const { handleSubmit, totals } = props;
  const [bandeira, setBandeira] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [acquirerNsu, setAcquirerNsu] = useState<string>('');
  const [statusPagamento, setStatusPagamento] = useState<string>("");
  const [acquirer, setAcquirer] = useState(-1);
  const [receiveFilter, setReceiveFilter] = useState<boolean>(true);
  const [paymenteFilter, setPaymenteFilter] = useState<boolean>(false);
  const [totalGross, setTotalGross] = useState();
  const [totalLiquid, setTotalLiquid] = useState();
  const [merchantId, setMerchantId] = useState<string>('');

  useEffect(() => {
    props.setFieldValue('initialDate', new Date());
    props.setFieldValue('finalDate', new Date());
  }, []);

  const formatMoney = (value:number) => {
    const formattedValue = (value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedValue;
  };

  const getCurrentDateAsString = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  }

  const getValorVendaTotal = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalGross;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  // const getTicket = () => {
  //   let ticket = 0;
  //   const valorFormatado = formatMoney(ticket);
  //   return valorFormatado;
  // }

  const getValorLiquidoTotal = (): string => {
    let valor: number = 0;
    if (totals?.totalLiquid) valor = totals.totalLiquid;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValoresAReceber = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalPending;
    const valorFormatado: string = formatMoney(valor as number);
    return valorFormatado;
  }

  const getValoresPagos = (): string => {
    let valor: number = 0;
    if (totals) valor = totals.totalPayed;
    const valorFormatado: string = formatMoney(valor);
    return valorFormatado;
  }

  const handleChangeBandeira = (value: string) => {
    setBandeira(value);
    props.setFieldValue('bandeira', value);
  };
  const handleChangeTipoPagamento = (value: string) => {
    setTipoPagamento(value);
    props.setFieldValue('tipoPagamento', value);
  };

  const handleChangeStatus = (value: string) => {
    setStatusPagamento(value);
    props.setFieldValue('statusPagamento', value);
  };

  const handleBusinessNameChange = (event: any) => {
    setBusinessName(event.target.value);
    props.setFieldValue('businessName', event.target.value);
  };

  const handleAcquirerNsuChange = (event: any) => {
    setAcquirerNsu(event.target.value);
    props.setFieldValue('acquirerNsu', event.target.value);
  };

  const handleMerchantIdChange = (event: any) => {
    setMerchantId(event.target.value);
    props.setFieldValue('merchantId', event.target.value);
  };

  const handleChangeAcquirer = (value: number) => {
    setAcquirer(Number(value));
    props.setFieldValue('acquirer', Number(value));
  };

  const handleReceiveFilterSelection = (value: any) => {
    if (value.target.checked) {
      setReceiveFilter(true);
      setPaymenteFilter(false);
      props.setFieldValue('saleInitialDate', undefined);
      props.setFieldValue('saleFinalDate', undefined);
    } else {
      setReceiveFilter(false);
      props.setFieldValue('initialDate', undefined);
      props.setFieldValue('finalDate', undefined);
    }
  };

  const handlePaymentFilterSelection = (value: any) => {
    if (value.target.checked) {
      setPaymenteFilter(true);
      setReceiveFilter(false);
      props.setFieldValue('initialDate', undefined);
      props.setFieldValue('finalDate', undefined);
    } else {
      setPaymenteFilter(false);
      props.setFieldValue('saleInitialDate', undefined);
      props.setFieldValue('saleFinalDate', undefined);
    }
  };

  return (
    <>
      <ValueCard>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor Líquido: </p>
          <p>{getValorLiquidoTotal()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valor das Vendas: </p>
          <p>{getValorVendaTotal()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valores Pagos: </p>
          <p>{getValoresPagos()}</p>
        </div>
        <div>
          <p style={{fontWeight: 'bolder'}}>Valores a Receber: </p>
          <p>{getValoresAReceber()}</p>
        </div>
      </ValueCard>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Label style={{marginBottom: '7px'}}>Filtrar por:</Label>
        </Row>
        <Row
          gutter={16}
        >

          <FilterContainer>
            <Checkbox
              onChange={handleReceiveFilterSelection}
              checked={receiveFilter}
              >Data de recebimento
            </Checkbox>

            <Checkbox
              onChange={handlePaymentFilterSelection}
              checked={paymenteFilter}
              >Data de venda
            </Checkbox>

          </FilterContainer>

          <FilterContainer>

            {receiveFilter && (
              <FilterRow>
                <DateView>
                  <DatePickerField
                    name="initialDate"
                    placeholder="Data inicial"
                  />
                </DateView>
                <DateView>
                  <DatePickerField
                    name="finalDate"
                    placeholder="Data final"
                  />
                </DateView>
              </FilterRow>
            )}

            {paymenteFilter && (
            <FilterRow>
              <DateView>
                <DatePickerField
                  name="saleInitialDate"
                  placeholder="Data inicial"
                />
              </DateView>
              <DateView>
                <DatePickerField
                  name="saleFinalDate"
                  placeholder="Data final"
                />
              </DateView>
            </FilterRow>
            )}

              <FilterRow>
              <DateView>
                <Select
                  defaultValue=""
                  style={{width: '100%'}}
                  onChange={handleChangeBandeira}
                  options={[
                    {
                      value: '',
                      label: 'Bandeira',
                    },
                    {
                      value: '3',
                      label: 'AMEX',
                    },
                    {
                      value: '171',
                      label: 'ELO',
                    },
                    {
                      value: '9',
                      label: 'HIPERCARD',
                    },
                    {
                      value: '2',
                      label: 'MASTERCARD',
                    },
                    {
                      value: '1',
                      label: 'VISA',
                    },
                    {
                      value: '999',
                      label: 'PIX',
                    },
                  ]}
                />
              </DateView>
              <DateView>
                <Select
                  defaultValue=""
                  style={{width: '100%'}}
                  onChange={handleChangeTipoPagamento}
                  options={[
                    {
                      value: '',
                      label: 'Tipo pagamento',
                    },
                    {
                      value: '001',
                      label: 'Crédito à Vista',
                    },
                    {
                      value: '002',
                      label: 'Parcelamento sem Juros',
                    },
                    {
                      value: '003',
                      label: 'Parcelamento com Juros',
                    },
                    {
                      value: '004',
                      label: 'Cancelamento',
                    },
                    {
                      value: '005',
                      label: 'Débito',
                    },
                    {
                      value: '006',
                      label: 'Pré-Autorização',
                    },
                    {
                      value: '099',
                      label: 'Pix',
                    },
                  ]}
                />
              </DateView>
            </FilterRow>

            {(user.userType === 3 || user.userType === 6) && (
            <FilterRow>
              <DateView>
                <input placeholder="Empresa" onChange={handleBusinessNameChange} />
              </DateView>
              <DateView>
                <input placeholder="NSU" onChange={handleAcquirerNsuChange} />
              </DateView>
              <DateView>
                <input placeholder="Cod. Lojista" onChange={handleMerchantIdChange} />
              </DateView>
            </FilterRow>
            )}

            <FilterRow>
              <DateView>
                <Select
                  defaultValue=""
                  style={{width: '100%'}}
                  onChange={handleChangeStatus}
                  options={[
                    {
                      value: '',
                      label: 'Status',

                    },
                    {
                      value: 'Pending',
                      label: 'Pendente',
                    },
                    {
                      value: 'Cancelled',
                      label: 'Cancelado',
                    },
                    {
                      value: 'Payed',
                      label: 'Pago',
                    },
                  ]}
                />
              </DateView>
              {user.userType === 3 && (
                <DateView>
                  <Select
                    defaultValue={-1}
                    style={{width: '100%'}}
                    onChange={handleChangeAcquirer}
                    options={[
                      {
                        value: -1,
                        label: 'Adquirente',
                      },
                      {
                        value: 3,
                        label: 'Adiq',

                      },
                      {
                        value: 10,
                        label: 'Rede',
                      },
                      {
                        value: 99,
                        label: 'Valori',
                      },
                    ]}
                  />
                </DateView>
              )}

              <Button style={{ marginRight: '8px', marginLeft: '8px', width: '150px', background: '#fbb651'}} htmlType="submit">
                Consultar
              </Button>

            </FilterRow>
          </FilterContainer>
        </Row>
      </Form>
    </>
  )
}
export default withFormik<IFormProps, PainelVendasFilterProps>({
  mapPropsToValues: (): PainelVendasFilterProps => ({
    initialDate: '',
    finalDate: '',
    saleInitialDate: '',
    saleFinalDate: '',
    bandeira: '',
    statusPagamento: '',
    tipoPagamento: '',
    businessName: '',
    acquirerNsu: '',
    acquirer: -1,
  }),
  validationSchema: schema,
  handleSubmit: (values, { props }) => {
    // if (values.initialDate == null || values.finalDate == null) {
    //   toast.error('Preencha as datas corretamente!')
    //   return;
    // }

    const initialDateValue = values.initialDate;
    const finalDateValue = values.finalDate;

    const saleInitialDateValue = values.saleInitialDate;
    const saleFinalDateValue = values.saleFinalDate;

    const dateI = formatDate(initialDateValue);
    const dateF = formatDate(finalDateValue);

    const saleDateI = formatDate(saleInitialDateValue);
    const saleDateF = formatDate(saleFinalDateValue);

    const test = {
      ...values,
      initialDate: dateI,
      finalDate: dateF,
      saleInitialDate: saleDateI,
      saleFinalDate: saleDateF
    }
    props.onFormSubmit(test);
  },
})(FormFilter);
